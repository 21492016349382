import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "mt-1" }
const _hoisted_2 = {
  key: 0,
  class: "card-footer px-3 border-0 d-flex align-items-center justify-content-between"
}
const _hoisted_3 = { "aria-label": "Page navigation example" }
const _hoisted_4 = { class: "pagination mb-0" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "page-link" }
const _hoisted_7 = { class: "font-weight-bold small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.totalRecord > _ctx.dataCount)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("nav", _hoisted_3, [
            _createElementVNode("ul", _hoisted_4, [
              (_ctx.currentPage > 0)
                ? (_openBlock(), _createElementBlock("li", {
                    key: 0,
                    class: "page-item",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ChangePage(_ctx.currentPage - 1)))
                  }, _cache[2] || (_cache[2] = [
                    _createElementVNode("a", { class: "page-link" }, "Previous", -1)
                  ])))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (item, key) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: key,
                  class: _normalizeClass(["page-item", item === _ctx.currentPage ? 'active' : '']),
                  onClick: ($event: any) => (_ctx.ChangePage(item))
                }, [
                  _createElementVNode("a", _hoisted_6, _toDisplayString(item + 1), 1)
                ], 10, _hoisted_5))
              }), 128)),
              (_ctx.currentPage < parseInt(_ctx.totalPage.toString()))
                ? (_openBlock(), _createElementBlock("li", {
                    key: 1,
                    class: "page-item",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.ChangePage(_ctx.currentPage + 1)))
                  }, _cache[3] || (_cache[3] = [
                    _createElementVNode("a", { class: "page-link" }, "Next", -1)
                  ])))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[4] || (_cache[4] = _createTextVNode(" Showing ")),
            _createElementVNode("b", null, _toDisplayString(_ctx.dataCount), 1),
            _cache[5] || (_cache[5] = _createTextVNode(" out of ")),
            _createElementVNode("b", null, _toDisplayString(_ctx.totalRecord), 1),
            _cache[6] || (_cache[6] = _createTextVNode(" entries "))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}