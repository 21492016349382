import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  class: "dropdown-menu",
  "aria-labelledby": "dropdownMenuButton1"
}
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[0] || (_cache[0] = _createElementVNode("button", {
      class: "btn btn-dark btn-sm dropdown-toggle",
      type: "button",
      id: "dropdownMenuButton1",
      "data-bs-toggle": "dropdown",
      "aria-expanded": "false"
    }, " Filter By ", -1)),
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.filters), (key, index) => {
        return (_openBlock(), _createElementBlock("li", { key: index }, [
          _createElementVNode("a", {
            class: "dropdown-item",
            onClick: ($event: any) => (_ctx.ChangeValue(key === 'ALL' ? undefined : key))
          }, _toDisplayString(_ctx.filters[key]), 9, _hoisted_2)
        ]))
      }), 128))
    ])
  ]))
}