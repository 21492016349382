import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "btn-group" }
const _hoisted_2 = { class: "dropdown-menu dropdown-menu-xs dropdown-menu-right" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "icon icon-small ml-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("button", {
      class: "btn btn-link text-decoration-none text-dark dropdown-toggle dropdown-toggle-split m-0 p-0",
      "data-bs-toggle": "dropdown",
      "aria-haspopup": "true",
      "aria-expanded": "false"
    }, [
      _createElementVNode("span", { class: "icon icon-sm icon-gray" }, [
        _createElementVNode("span", { class: "fas fa-cog" })
      ]),
      _createElementVNode("span", { class: "sr-only" }, "Toggle Dropdown")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("span", { class: "dropdown-item font-weight-bold text-dark" }, "Show", -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.count, (batch, index) => {
        return (_openBlock(), _createElementBlock("a", {
          key: index,
          class: "dropdown-item d-flex font-weight-bold",
          onClick: ($event: any) => (_ctx.ChangeValue(batch))
        }, [
          _createTextVNode(_toDisplayString(batch) + " ", 1),
          (batch === _ctx.modelValue)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _cache[0] || (_cache[0] = [
                _createElementVNode("span", { class: "fas fa-check" }, null, -1)
              ])))
            : _createCommentVNode("", true)
        ], 8, _hoisted_3))
      }), 128))
    ])
  ]))
}