import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  colspan: "8",
  class: "text-center"
}
const _hoisted_2 = { class: "font-weight-bold" }
const _hoisted_3 = { class: "font-weight-normal" }
const _hoisted_4 = { class: "font-weight-normal" }
const _hoisted_5 = { class: "font-weight-normal" }
const _hoisted_6 = { class: "font-weight-normal" }
const _hoisted_7 = { class: "font-weight-normal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tr", null, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("td", _hoisted_1, _cache[0] || (_cache[0] = [
          _createElementVNode("span", {
            class: "font-weight-bolder",
            style: {"font-size":"1.5em"}
          }, "Loading ...", -1)
        ])))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("td", null, [
            _createElementVNode("a", _hoisted_2, _toDisplayString(_ctx.dt.id), 1)
          ]),
          _createElementVNode("td", null, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.dt.description), 1)
          ]),
          _createElementVNode("td", null, _toDisplayString(_ctx.dt.type), 1),
          _createElementVNode("td", null, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.dt.User.Profile.first_name) + " " + _toDisplayString(_ctx.dt.User.Profile.last_name), 1)
          ]),
          _createElementVNode("td", null, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.dt.User.username), 1)
          ]),
          _createElementVNode("td", null, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.dt.User.type), 1)
          ]),
          _createElementVNode("td", null, [
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.formatDate(_ctx.dt.created_at)), 1)
          ])
        ], 64))
  ]))
}