
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  name: "Pagination",
  props: ["currentPage", "totalRecord", "dataCount"],
  emits: ["update:modelValue", "updated"],
  setup(props, context) {
    const maxPage = 10;
    const totalPage = computed(() => props.totalRecord / props.dataCount);

    const pages = computed(function () {
      const pages: number[] = [];
      pages.push(props.currentPage);
      // Recompute for props reactivity
      for (let i = 1; i < maxPage; i++) {
        if (i > totalPage.value) {
          break;
        }

        if (i === props.currentPage) {
          continue;
        }

        pages.push(i);
      }

      pages.sort();

      return pages;
    });

    function ChangePage(newPage: number) {
      console.log(totalPage.value)
      if (newPage === props.currentPage) {
        return;
      }
      context.emit("update:modelValue", newPage);
      context.emit("updated");
    }

    return {
      ChangePage,
      pages,
      totalPage,
    };
  },
});
